<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            class="export-btn"
            >新增</el-button
          >
        </div>
        <el-form :inline="true" size="mini">
          <AreaField
            v-model="searchParams.area_code"
            @change="getStationList"
          />
          <el-form-item>
            <el-select
              v-model="searchParams.site_id"
              filterable
              :clearable="true"
              @change="changeSite"
              placeholder="加油站（可输入）"
            >
              <el-option
                v-for="item in gasData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchParams.tanker_id"
              filterable
              :clearable="true"
              placeholder="加油机"
            >
              <el-option
                v-for="item in searchTanker"
                :key="item.tanker_id"
                :value="item.tanker_id"
                :label="item.tanker_name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          type="primary"
          size="small"
          @click="
            handleEdit(scope.row);
            eleformBtn = false;
            formDisabled = true;
          "
        >
          详情
        </el-button>
        <el-button type="primary" size="small" @click="handleEdit(scope.row)">
          编辑
        </el-button>
        <el-button type="danger" size="small" @click="handleDel(scope.row.id)"
          >删除</el-button
        >
      </template>
    </default-table>

    <el-dialog
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :title="dialogTitle"
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <ele-form
        v-model="formData"
        :form-desc="formDesc"
        labelPosition="right"
        ref="form"
        :span="24"
        :isShowSubmitBtn="eleformBtn"
        :isShowCancelBtn="eleformBtn"
        :isShowBackBtn="false"
        :request-fn="handleSubmit"
        :isShowErrorNotify="false"
        @cancel="handleCancel"
        :form-attrs="{
          size: 'large'
        }"
        :disabled="formDisabled"
        :label-width="'150'"
      >
        <template v-slot:site_id="{}">
          <el-select
            v-model="formData.site_id"
            filterable
            :clearable="true"
            placeholder="请选择"
            @change="value => getTankerList(value, true)"
            style="width: 100%"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </template>
        <template v-slot:tanker_id="{}">
          <el-select
            v-model="formData.tanker_id"
            filterable
            :clearable="true"
            placeholder="请选择"
            @change="getTaxControlNum"
            style="width: 100%"
          >
            <el-option
              v-for="item in tankerList"
              :key="item.tanker_id"
              :value="item.tanker_id"
              :label="item.tanker_name"
            />
          </el-select>
        </template>

        <template v-if="formData.num_1 * 1 > 0" v-slot:mainboard_seal-wrapper>
          <selects
            :label-title="'主板铅封序列号'"
            :number="formData.num_1 * 1"
            :data="seal_1"
          >
          </selects>
        </template>
        <template v-if="formData.num_2 * 1 > 0" v-slot:encoder_seal-wrapper>
          <selects
            :label-title="'编码器铅封序列号'"
            :number="formData.num_2 * 1"
            :data="seal_2"
          >
          </selects>
        </template>
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import {
  queryLeadSealList,
  addSealNum,
  delSealNum,
  getTankerList
} from '@/api/lead_seal_number';
import { formDesc, columns } from './columns';
import EleForm from 'vue-ele-form';
import mixins from '@/mixins';
import Selects from './components/selects.vue';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable,
    EleForm,
    Selects
    // List
  },
  data() {
    return {
      tableLoading: true,
      visible: false,
      detailInfo: [],
      formData: {},
      searchParams: {},
      searchTanker: [],
      columns,

      dialogTitle: '',
      formDesc,
      tankerList: [],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      formDisabled: false,
      eleformBtn: true,
      seal_1: [],
      seal_2: []
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      queryLeadSealList(params).then(res => {
        this.data = [];
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    async changeSite(e) {
      if (e) {
        const res = await getTankerList({ site_id: e });
        if (res.code == 0) {
          this.searchTanker = res.data;
        } else {
          this.searchTanker = [];
        }
      }
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: this.pagination.pageSize
      });
    },
    // 根据加油站获取加油机列表
    async getTankerList(e, falg) {
      const result = await getTankerList({ site_id: e });

      if (falg) {
        this.formData.tax_control_num = '';
        this.formData.tanker_id = undefined;
      }
      if (result.code == 0) {
        this.tankerList = result.data;
      } else {
        this.tankerList = [];
      }
    },
    getTaxControlNum(e) {
      console.log(e);
      // let tankerId = this.formData.tanker_id;
      let row = this.tankerList.filter(item => item.tanker_id === e);
      console.log(row);
      this.formData.tax_control_num = row[0].tax_control_num;
      this.$forceUpdate();
    },
    handleAdd() {
      this.dialogTitle = '新增';
      this.visible = true;
    },
    handleEdit(e) {
      this.dialogTitle = '详情';
      this.visible = !this.visible;
      this.formData = e;
      this.seal_1 = e.mainboard_seal;
      this.seal_2 = e.encoder_seal;
      this.seal_1.unshift('');
      this.seal_2.unshift('');
      this.getTankerList(this.formData.site_id);
    },
    handleDel(id) {
      this.$confirm('是否删除该序列号？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delSealNum({ id }).then(res => {
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.handleCancel();
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'warning'
              });
            }
          });
        })
        .catch(() => {});
    },
    async handleSubmit() {
      this.formData.mainboard_seal = this.seal_1.slice(
        1,
        this.formData.num_1 + 1
      );
      this.formData.encoder_seal = this.seal_2.slice(
        1,
        this.formData.num_2 + 1
      );
      this.formDisabled = true;
      addSealNum(this.formData)
        .then(result => {
          this.formDisabled = false;
          if (result && result.code === 0) {
            this.$message.success(result.msg);
            this.handleCancel();
            return result;
          }
        })
        .catch(err => {
          this.formDisabled = false;
          this.$message({
            showClose: true,
            message: err,
            type: 'warning'
          });
          this.handleCancel();
        });
    },
    handleCancel() {
      this.visible = false;
      this.formData = {
        num_1: 1,
        num_2: 1
      };
      this.seal_1 = [];
      this.seal_2 = [];
      this.formDisabled = false;
      this.eleformBtn = true;
      this.$refs['form'] && this.$refs['form'].resetForm();
      this.handleQuery({
        current: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
    }
  }
};
</script>
