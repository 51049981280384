// import { getTankerList } from '@/api/lead_seal_number';
// import { getTax } from './index.vue';
// import { getGasData } from '@/api/global';

export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 80
  },
  {
    title: '监管设备编号',
    key: 'tax_control_num',
    minWidth: 150
  },
  {
    title: '主板铅封数',
    key: 'num_1',
    // width: 140,
    minWidth: 100
  },
  {
    title: '编码器铅封数',
    key: 'num_2',
    minWidth: 100
  },
  {
    title: '操作',
    key: 'actions',
    width: 220,
    extraType: 'button',
    scopedSlots: { customRender: 'actions' }
  }
];

export const formDesc = {
  // site_id: {
  //   type: 'select',
  //   label: '加油站名称',
  //   layout: 24,
  //   rules: [{ required: true, message: '请选择加油站' }],
  //   prop: { text: 'name', value: 'id' },
  //   options: async () => {
  //     const result = await getGasData();
  //     if (result && result.code === 0) {
  //       return result.data;
  //     } else {
  //       return [];
  //     }
  //   },
  //   attrs: {
  //     filterable: true
  //   }
  // },
  // tanker_id: {
  //   type: 'select',
  //   label: '加油机名称',
  //   optionsLinkageFields: ['site_id'],
  //   layout: 24,
  //   rules: [{ required: true, message: '请输入加油机' }],
  //   prop: { text: `tanker_name`, value: 'tanker_id' },
  //   options: async data => {
  //     if (data.site_id) {
  //       // data.tanker_id === null || data.tanker_id === undefined ? data.tanker_id = undefined : '';
  //       const result = await getTankerList({ site_id: data.site_id });
  //       console.log('tanker_id', data.tanker_id);
  //       if (result && result.code === 0) {
  //         return result.data;
  //       } else {
  //         return [];
  //       }
  //     } else {
  //       return [];
  //     }
  //   },
  //   attrs: {
  //     filterable: true
  //   }
  // },
  // tax_control_num: {
  //   type: 'select',
  //   label: '监管设备编号',
  //   layout: 24,
  //   disabled: true,
  //   optionsLinkageFields: ['site_id', 'tanker_id'],
  //   options: async data => {
  //     console.log('tax', data);
  //     data.tax_control_num = data.tanker_id;
  //     return [
  //       { text: data.tanker_id, value: data.tanker_id }
  //     ]
  //   },
  // },

  site_id: {
    type: 'input',
    layout: 24,
    rules: [{ required: true, message: '请选择加油站' }],
    label: '加油站名称'
  },

  tanker_id: {
    type: 'input',
    layout: 24,
    rules: [{ required: true, message: '请输入加油机' }],
    label: '加油机名称'
  },

  tax_control_num: {
    type: 'input',
    layout: 24,
    label: '监管设备编号',
    disabled: true
  },

  num_1: {
    type: 'select',
    label: '主板铅封数',
    default: 1,
    options: [1, 2, 3, 4],
    layout: 24,
    rules: [{ required: true, message: '请输入主板铅封数' }]
    // attrs: {
    //   clearable: true
    // }
  },
  mainboard_seal: {
    type: 'input',
    layout: 12,
    label: '铅封序列号1'
  },
  num_2: {
    type: 'select',
    label: '编码器铅封数',
    default: 1,
    options: [1, 2, 3, 4],
    layout: 24,
    rules: [{ required: true, message: '请输入编码器铅封号' }]
    // attrs: {
    //   clearable: true
    // }
  },

  encoder_seal: {
    type: 'input',
    layout: 12,
    label: '编码器铅封号1'
  }

  // mainboard_seal: {
  //   type: 'input',
  //   label: '主板铅封序列号',
  //   layout: 24,
  //   rules: [{ required: true, message: '请输入主板铅封序列号' }]
  // },
  // encoder_seal: {
  //   type: 'input',
  //   label: '编码器铅封序列号',
  //   layout: 24,
  //   rules: [{ required: true, message: '请输入编码器铅封序列号' }]
  // }
};
